<template>
   <v-expand-transition>
      <v-form ref="form">
         <div v-if="typeof location.id === 'string' || location.id >= 0">
            <v-card-text class="pt-9">
               <v-row>
                  <v-col cols="12" md="4">
                     <v-select v-model="form.building" :items="buildings" label="Building" placeholder="Pilih Gedung" outlined hide-details required :rules="[v => !!v]"
                        item-text="name" item-value="identity"
                        :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                        />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field v-model="form.name" label="Name" placeholder="Nama Lantai" outlined hide-details required :rules="[v => !!v]">
                        <template slot="append-outer">
                           <v-btn large height="56" width="70" icon tile @click="$refs.locationView.click()">
                              <v-avatar v-if="form.View" tile height="54" width="70">
                                 <img :src="setUrlFromImage(form.View)" alt="View" style="object-fit: cover;" @error="() => { form.View = '' }">
                              </v-avatar>
                              <v-icon v-else tile size="84">
                                 mdi-image-area
                              </v-icon>
                              <input ref="locationView" type="file" accept="image/*" alt="locationView" style="display:none;" @change="attachmentPreview">
                           </v-btn>
                        </template>
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.identity" label="Code" placeholder="Kode Lantai" outlined hide-details />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.access" label="Access" placeholder="Nomor Kartu" outlined hide-details />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text class="pb-6">
               <v-row class="mt-0">
                  <v-col cols="12" class="py-0 d-flex">
                     <v-btn v-if="$hasRole(location.kind.toLowerCase(), 'CU')" x-large color="accent" depressed class="mr-4" :loading="$store.state.process === 'updateLocation'" @click="updateRecord">
                        Update
                     </v-btn>
                     <v-btn x-large color="accent" depressed outlined class="mr-4" @click="location.id = -1">
                        {{ $store.state.process === 'updateLocation' ? 'Close' : 'Cancel' }}
                     </v-btn>
                     <v-spacer />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
         </div>
      </v-form>
   </v-expand-transition>
</template>

<script>
import { imageFromUrl, blobImageToBase64 } from '@/utils/nirtara'
import { floor } from '@/utils/fields.js'
export default {
   name: 'NFloorForm',

   props: {
      location: { type: Object },
      buildings: { type: Array },
   },
   data: () => ({
      isUsed: false,
      form: Object.assign({}, floor),
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
   },
   watch: {
      'location.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         this.form = Object.assign({}, floor, typeof id === 'string' ? this.location : { id: 0 })
         this.buildings.length === 1 && id === 0 && (this.form.building = this.buildings[0].identity)
      },
   },
   methods: {
      attachmentPreview (event) {
         blobImageToBase64(this.$refs[event.target.alt].files[0])
         .then(base64 => {
            switch (event.target.alt) {
               default: console.log(`${event.target.alt}: ${base64.substr(0, 32)}....`)
                  break
               case 'locationView': this.form.View = base64
                  break
            }
         })
      },
      updateRecord () {
         if (!this.$refs.form.validate()) return
         this.$emit('update', this.form)
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
::v-deep .v-text-field--outlined .v-input__append-outer {
   margin: auto 0 auto 16px;
}
.v-btn:not(.v-btn--round).v-size--x-large {
   min-width: 115px;
}
</style>
